import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const SArrowButton = styled(ButtonTransparent)`
  margin-bottom: ${size(2)};

  &,
  ${TextBody} {
    color: ${color('night-l-100')};
  }

  @media ${media.gt('mobile')} {
    margin-bottom: 0;
  }
  @media ${media.lte('mobile')} {
    width: 100%;
  }
`

export const SImagePreviewWrapper = styled.div`
  margin-top: ${size(8)};
  position: relative;
  height: 472px;
  margin-bottom: ${size(7)};
  @media ${media.lte('MD')} {
    height: ${size(75)};
  }
  @media ${media.lte('mobile')} {
    height: ${size(40)};
  }
`

export const SImagePreview = styled.img`
  position: absolute;
  width: 150%;
  top: 0;
  left: -25%;
  border-radius: ${radius('corner')};
  height: ${size(118)};
  object-fit: cover;
  cursor: pointer;
  @media ${media.lte('MD')} {
    width: calc(100% + 30px);
    left: -15px;
    border-radius: 0;
    height: ${size(75)};
  }
  @media ${media.lte('mobile')} {
    height: ${size(40)};
  }
`

export const modalStyles = css`
  width: 100%;
  height: 80%;
  max-width: 1060px;
  max-height: 815px;
  padding: 0;
  overflow: unset;
  background-color: transparent;
  box-shadow: none;
  & button {
    top: 0px;
    right: -84px;
    border-radius: ${radius('full')};
    width: 48px;
    height: 48px;
    border: 1px solid ${color('white', 0.5)};
    color: ${color('white')};
    @media ${media.lte('LG')} {
      top: -64px;
      right: 0;
    }
    @media ${media.lte('MD')} {
      right: ${size(4)};
    }
    & svg {
      height: 24px;
      width: 24px;
    }
  }
  @media ${media.lte('XL')} {
    width: 75%;
  }
  @media ${media.lte('LG')} {
    width: 95%;
    height: 70%;
  }
  @media ${media.lte('MD')} {
    width: 100%;
    height: 60%;
    left: 0;
  }
`

export const modalBackgroundStyles = css`
  background-color: ${color('black', 0.8)};
`
