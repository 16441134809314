import styled from '@emotion/styled'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, font, radius } from 'driverama-core/styles/variables'

export const SContentHeading = styled(TextHeader)`
  margin: ${size(14)} 0 ${size(4)} 0;
`

export const SContentText = styled(TextBody)`
  display: block;
  margin-bottom: ${size(8)};

  color: ${color('night-text')};
  line-height: ${size(6)};
`

export const SContentImage = styled.img`
  position: absolute;
  width: 150%;
  top: 0;
  left: -25%;
  border-radius: ${radius('corner')};
  height: ${size(118)};
  object-fit: cover;

  @media ${media.lte('MD')} {
    width: calc(100% + 30px);
    left: -15px;
    border-radius: 0;
    height: ${size(75)};
  }

  @media ${media.lte('mobile')} {
    height: ${size(40)};
  }
`

export const SImageWrapper = styled.div``

export const SContentLink = styled.a`
  font-family: ${font('text')};
  color: ${color('night-l-100')};

  &:any-link {
    color: ${color('night-l-100')};
  }

  outline: none;
`

export const SContentQuote = styled.blockquote`
  border-left: 2px solid ${color('night-l-200')};
  padding-left: ${size(4)};
  color: ${color('night-text')};
  font-style: italic;
  margin: ${size(4)} 0;
`

export const SContentOrderedList = styled.ol`
  list-style: none;
  counter-reset: li;
  margin: ${size(4)} 0;

  & li::before {
    content: counter(li) '.';
    color: ${color('night-l-200')};
    display: inline-block;
    width: ${size(4)};
    margin-right: ${size(2)};
  }

  & li {
    counter-increment: li;
  }

  ${SContentText} {
    display: inline-block;
    margin-bottom: 0;
  }

  & > li {
    & > ol {
      margin: 0;
      margin-left: ${size(6)};
    }
  }
`

export const SContentUnorderedList = styled.ul`
  font-family: ${font('text')};
  margin: ${size(4)} 0;

  & li::before {
    content: '•';
    margin-right: ${size(2)};
    color: ${color('night-l-100')};
  }

  ${SContentText} {
    display: inline;
    margin-bottom: 0;
  }

  & > li {
    display: flex;

    & > ul {
      margin: 0;
      margin-left: ${size(6)};
    }
  }
`

export const SIFrameContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`
