import { TextBody } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import {
  modalBackgroundStyles,
  modalStyles,
  SArrowButton,
  SImagePreview,
  SImagePreviewWrapper
} from './ImageGalleryPreview.styled'
import IconArrowRight from 'images/icons/IconArrowRight.svg'
import { size } from 'driverama-core/styles/spacing'
import { useModal } from 'driverama-core/components/modal/Modal'
import { ArticleLinksFragment } from 'api/contentful/generated/contentful'
import { ImagesGallery } from 'driverama-core/components/imagesGallery/ImagesGallery'
import {
  remapGalleryLinks,
  resolveGalleryLinks
} from './ImageGalleryPreview.utils'

export function ImageGalleryPreview(props: {
  links?: ArticleLinksFragment | undefined
}) {
  const images = remapGalleryLinks(props?.links)
  const { t } = useTranslation(['core', 'blog'])
  const [modal, openModal] = useModal(
    () => (
      <ImagesGallery images={images} isImageListHidden isMagnifierDisabled />
    ),
    {
      wrapperStyles: modalStyles,
      backgroundStyles: modalBackgroundStyles,
      closeLabel: t('core:close')
    }
  )
  const handleGalleryOpen = async () => await openModal()
  const items = resolveGalleryLinks(props.links)
  return (
    <>
      {modal}
      <SImagePreviewWrapper onClick={handleGalleryOpen}>
        <SImagePreview src={items?.[0]?.url ?? ''} />
      </SImagePreviewWrapper>
      <SArrowButton onClick={handleGalleryOpen}>
        <TextBody variant="setup">
          {t('blog:blog_detail_see_all_photos', {
            count: items?.length ?? 0
          })}
        </TextBody>
        <IconArrowRight style={{ marginLeft: size(4) }} />
      </SArrowButton>
    </>
  )
}
