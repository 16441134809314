import { ArticleLinksFragment } from 'api/contentful/generated/contentful'

export const remapGalleryLinks = (links: ArticleLinksFragment | undefined) => {
  const items = resolveGalleryLinks(links)
  const images = items?.map(image => {
    return {
      url: image?.url
    }
  })
  return images ? images.filter(img => img && img.url) : []
}

export function resolveGalleryLinks(links?: ArticleLinksFragment | undefined) {
  const block = links?.entries?.block[0]

  return block?.__typename === 'ImageGallery'
    ? block?.imagesCollection?.items
    : undefined
}
